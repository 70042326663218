import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import { Overlay, Text, Flex, Box } from '@pancakeswap/uikit'
import LangSelector from '@pancakeswap/uikit/src/components/LangSelector/LangSelector'
import { useMenuItems } from './hooks/useMenuItems'

const MobileNavSwapper = styled.div`
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 9;
  width: 100vw;
  height: calc(100vh - 56px);
  .logo {
    display: flex;
    align-items: center;
    margin-right: 10px;
    img {
      width: 40px;
    }
  }
  .logo1 {
    display: flex;
    align-items: center;

    img {
      width: 24px;
    }
  }
`
const OverSwapper = styled.div`
  width: 30%;
  height: 100%;
  background: rgba(97, 96, 101, 0.5);
`

const Nav = styled.div`
  width: 70%;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  height: 100%;
  padding-top: 1rem;
`

const NavItem = styled.div`
  display: flex;
  padding: 0.8vh 1rem;
  justify-content: space-between;
  align-items: center;
  .pagename {
    flex: 1;
  }
  &:hover {
    background: rgba(253, 236, 242, 0.5);
  }
  button {
    padding: 0;
  }
  svg {
    margin-right: 0;
  }
`
const LogSwapper = styled.div`
  position: absolute;
  bottom: 2rem;
  padding-left: 1rem;
  .imageSwapper {
    display: flex;
    margin-bottom: 1rem;
  }
`
interface PropsType {
  callBack: () => void
  currentLang: any
  langs: any
  setLang: any
}

function MobileNav(props: PropsType) {
  const menuItems = useMenuItems()

  const { t } = useTranslation()
  const router = useRouter()
  const { callBack, currentLang, langs, setLang } = props
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    if (router.pathname === currentPage) callBack()
  }, [router])

  const itemClick = (url: string) => {
    router.push(url)
    setCurrentPage(url)
  }
  const overClick = () => {
    callBack()
  }

  return (
    <MobileNavSwapper>
      <OverSwapper onClick={overClick} />
      <Nav>
        {menuItems.map((item) => (
          <NavItem onClick={() => itemClick(item.href)} key={item.href}>
            <div className="logo1">
              <img src={`/images/rightnav/${item.icon}.png`} alt="" />
            </div>
            <Text className="pagename" ml=".7rem" fontSize="14px" bold>
              {t(item.label)}
            </Text>
            <div className="logo1">
              <img src={`/images/rightnav/right.png`} alt="" />
            </div>
          </NavItem>
        ))}
        <NavItem>
          <Flex alignItems="center">
            <Box mt="4px">
              <LangSelector
                currentLang={currentLang}
                langs={langs}
                setLang={setLang}
                buttonScale="xs"
                color="textSubtle"
                hideLanguage
              />
            </Box>
          </Flex>
          <div className="logo1">
            <img src={`/images/rightnav/right.png`} alt="" />
          </div>
        </NavItem>

        <LogSwapper>
          <div className="imageSwapper">
            {/* <div className="logo">
              <img src="/images/rightnav/logo.png" alt="https://t.me/maleduck666" />
            </div>
            <div className="logo">
              <img src={`/images/rightnav/telegram.png`} alt="https://t.me/maleduck666" />
            </div> */}
            {/* <div className="logo1">
              <img src={`/images/rightnav/face.png`} alt="" />
            </div>
            <div className="logo1">
              <img src={`/images/rightnav/twi.png`} alt="" />
            </div>
            <div className="logo1">
              <img src={`/images/rightnav/mi.png`} alt="" />
            </div> */}
          </div>
          {/* <Text>Yagong</Text> */}
          {/* <Text>Blockchain Incubation Platfrom</Text> */}
        </LogSwapper>
      </Nav>
    </MobileNavSwapper>
  )
}

export default memo(MobileNav)
