import { ChainId, Token, WBNB } from '@pancakeswap/sdk'


 export const apeTokens = {

 WETH: new Token(
   33139,
   '0x48b62137EdfA95a428D35C09E44256a739F6B557',
   18,
   'WAPE',
   'WAPE',
   'https://pancakeswap.finance/',
 ),

// wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 18, 'WBTC', 'Wrapped BTC'),
}

