import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .notification {
    min-width: 80px;;
    min-height:60px;
    border-radius: 16px;
    font-weight: bold;
    padding: 0 0 0 16px;
    overflow: revert;
    top:6rem !important;
    cursor: pointer;


    .ant-notification-notice-message {
      color: #ffa72b;
    }
    .ant-notification-notice-close {
      color: #ffa72b;
    }
    .anticon.ant-notification-notice-icon-info,
    .anticon.ant-notification-notice-icon-success,
    .anticon.ant-notification-notice-icon-error,
    .anticon.ant-notification-notice-icon-warning{
      color: #fff;
      padding-top: 1rem;
    }
    .ant-notification-notice-message {
      min-height:60px;
      background: #fff;
      border-top-right-radius: 16px;
      display: flex;
      align-items: center;
      padding:.5rem 2.5rem .5rem .5rem;
    }
    .ant-notification-notice-closable 
    .ant-notification-notice-message {
      padding-right: 2.5rem;
      margin-bottom: 0;
    }
    .ant-notification-notice-description {
      background: #fff;
      border-bottom-right-radius: 16px;
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    },
    .ant-notification-notice-with-icon .ant-notification-notice-message{
      margin-bottom:0;
    }
  }

  .relative {
    position: relative;
  }
  .flexsbc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .addBtn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
`

export default GlobalStyle
